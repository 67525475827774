<template>
	<div class="friends">
		<Navbar class="navbar" />
		<div class="friends-layout">
			<section class="efan-users">
				<h3>Efan users</h3>
				<div class="efan-users__searchbar">
					<input
						type="text"
						v-model="query"
						@input="applyFilter"
						placeholder="Search by username"
						class="efan-users__searchbar--input"
					/>
					<img src="@/assets/icons/search.svg" class="efan-users__searchbar--icon" />
				</div>
				<!-- Show loading data -->
				<div class="loader" v-if="loadingUsers">
					<div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
				<div class="efan-users__empty" v-else-if="!loadingUsers && users.length === 0 && query.length > 0">
					User with username <span class="efan-users__empty--query">{{ query }}</span> was not found.
				</div>
				<div class="efan-users__list" ref="userProfiles" @scroll="onScroll" v-else>
					<UserProfileCard 
						class="efan-users__list--profile"
						:model="user" 
						v-for="user in users" 
						:key="user.id"
						@refresh="onFollow" />
					
					<div class="loader" v-if="loadingMore">
						<div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>

				</div>
			</section>
			<!-- <section class="user-friends">
				<h1>Your friends</h1>
				<UserProfileCard 
					class="friend" 
					:model="friend" 
					v-for="friend in friends" 
					:key="friend.id"
					@refresh="onFollow" />
	
				<h2 v-if="friends.length < 1">You haven't added any friends yet</h2>
			</section> -->
		</div>

	</div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import Navbar from "@/components/Navbar";
import UserProfileCard from "@/components/side/UserProfileCard";

export default {
	name: "Friends",
	components: { Navbar, UserProfileCard },
	data() {
		return {
			query: "",
			friends: [],
			timeoutId: null,
			loadingUsers: true,
			loadingMore: false,
			users: [],
			allUsersCount: 0,
			limit: 12,
			trackOffset: 0
		};
	},
	mounted() {
		this.getPlatformUsers();
		this.getData();
	},
	computed: {
		...mapStores(useUserStore),
	},
	methods: {
		onFollow() {
			this.getPlatformUsers(this.query, this.limit, this.offset);
			this.getData()
		},
		async getPlatformUsers(query = '', limit = 12, offset = 0, userId = this.userStore.user.id) {
			this.loadingUsers = true;
			this.allUsersCount = 0;
			this.trackOffset = 0;

			await this.$api.getPlatformUsers(userId, query, limit, offset, this.userStore.accessToken)
				.then(response => {
					const { success, users, userCount } = response;
					if(success) {
						this.users = users;
						this.allUsersCount = userCount;
					}
					this.loadingUsers = false;
				})
		},
		async getData() {
			await this.$api.getFriends(this.userStore.user.id, this.userStore.accessToken)
				.then(response => {
					if (response.success === true) 
						this.friends = response.friends;
				})
		},
		async onScroll() {
			const element = this.$refs.userProfiles;
			if (element.scrollHeight - element.scrollTop === element.clientHeight) {
				if(this.allUsersCount === this.users.length) {
					return;
				}
				this.trackOffset += 12;
				this.loadingMore = true;

				const query = this.query.trim();
				await this.$api.getPlatformUsers(this.userStore.user.id, query, this.limit, this.trackOffset, this.userStore.accessToken)
					.then(response => {
						const { success, users } = response;
						if(success) {
							this.users.push(...users);
						}
						this.loadingMore = false;
					})

				}
		},
		async applyFilter() {
			this.loadingUsers = true;
			if (this.timeoutId) {
				clearTimeout(this.timeoutId);
			}

			this.timeoutId = setTimeout(async () => {
				const query = this.query.trim();
				this.trackOffset = 0;
				await this.getPlatformUsers(query)
				this.loadingUsers = false;
			}, 500);
		}
	}
}	
</script>

<style lang="scss" scoped>
$width: 240px;
	.friends {
		&-layout {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: repeat(4, auto);
			justify-items: center;
            grid-template-areas: 
                ". efan-users ."
                ". . user-friends"
                ". loader .";

            margin-top: 20px;
            color: #fff;

			.efan-users {
				grid-area: efan-users;

				background-color: var(--dark);            
				border-radius: 15px;

				@include side-component;
				@include scrollbars($radius: 10px, $size: 15px);
				padding: 10px;
  				text-align: center;
				overflow-y: auto;

				h3 {
					@include side-component-title;
					margin-bottom: 15px;
				}

				&__searchbar {
					position: relative;
					width: $width;
					@include center;
					text-align: center;

					&--icon {
					@include white-filter;
					position: absolute;
					top: 6px;
					left: 8px;
					height: 1.6em;
					}

					&--input {
					box-sizing: border-box;
					@include text-input;
					@include remove-outline;
					padding: 10px;
					padding-left: 35px;
					margin-bottom: 5px;
					width: $width;

					background-color: transparent;
					border: 2px solid white;
					color: white;
					@include placeholder {
						color: white;
					}

					animation: leftToRight 0.5s;
					}
				}

				&__list {
					max-height: 800px;
					@include scrollbars($radius: 10px, $size: 15px);
					overflow-y: auto;

					&--profile {
						margin-right: 20px;
					}

					&--empty {
						font-size: 1.2rem;
						margin: 20px 0px;
					}
				}

				&__empty {
					font-size: 1.2rem;
					margin: 20px 0px;

					&--query {
						color: var(--orange);
					}
				}

				.loader {
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 20px 0px;

					div {
						display: inline-block;
						position: relative;
						width: 80px;
						height: 80px;

						div {
							box-sizing: border-box;
							display: block;
							position: absolute;
							width: 64px;
							height: 64px;
							margin: 8px;
							border: 8px solid white;
							border-radius: 50%;
							animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
							border-color: white transparent transparent transparent;

							&:nth-child(1) {
							animation-delay: -0.45s;
							}

							&:nth-child(2) {
							animation-delay: -0.3s;
							}

							&:nth-child(3) {
							animation-delay: -0.15s;
							}
						}
					}
				}
			}

			.user-friends {
				grid-area: user-friends;

				height: fit-content;
				
				background-color: var(--dark);            
				border-radius: 15px;

				@include scrollbars($size: 15px, $radius: 15px);
				overflow: auto;

				width: 90%;
				min-width: 200px;
				max-width: 500px;
				max-height: 800px;
				padding: 15px 0;
				text-align: center;

				h1 {
					@include side-component-title;
				}
				h2 {
					color: var(--text-darker);
				}
				.friend {
					margin: 0px 10%;
				}
        	}
        }
	}

@media screen and (max-width: 1300px) {
	.friends {
		&-layout {
			display: grid;
			grid-template-columns: 1fr 1.5fr 1fr;
			grid-template-rows: repeat(3, auto);
			grid-template-areas: 
				". efan-users ."
				". efan-users ."
				". loader .";

			margin-top: 20px;
			margin-right: 20px;
			margin-left: 20px;
			color: #fff;
		}
	}
}

@media screen and (max-width: 768px) {
	.friends {
		&-layout {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: repeat(3, auto);
			grid-template-areas: 
				"user-friends"
				"efan-users"
				"loader";

			margin: 20px;
			color: #fff;

			.user-friends {
				width: 100%;
				max-width: none;
				margin-bottom: 20px;
			}
		}
	}
}

@keyframes leftToRight {
  0% {
    width: 10px;
  }
  100% {
    width: $width;
  }
}
</style>